:root {
    font-size: 1vw;
}

* {
    margin: 0;
    max-width: 100%;
    /* overflow-x: hidden; */
}

/* @media (max-width: 1439px) {
    :root {
        font-size: 14px;
    }
}

@media (max-width: 1057px) {
    :root {
        font-size: 10px;
    }
} */

/* Mobile view */
@media (max-width: 767px) {
    :root {
        font-size: 2.2vw;
    }
}
