.Commitee {
    display: grid;
    grid-template-columns: 0fr 1fr;
    justify-content: center;
    align-items: center;

    .title {
        display: grid;
        justify-content: right;
        align-items: center;
        width: 8.5rem;
        border-right: 0.3rem solid #acacac;
        margin-right: 1rem;

        span {
            padding-right: 2rem;
            width: 3rem;
            font-size: 3.7rem;
            line-height: 4rem;
            overflow-wrap: break-word;
        }
    }

    .rows {
        display: grid;
        align-items: center;
        padding: 1rem;
        grid-template-columns: repeat(4, 1fr);
        gap: 2.5rem;
        margin: 1rem 1.5rem;
        overflow-y: scroll;
        height: 41rem;

        .row2 {
            padding-top: 5rem;
            width: 100%;
            cursor: pointer;
            border-radius: 1rem;
            border: solid 0.1rem #acacac;
            border-bottom: solid 0.4rem orange;
            height: 24rem;
            text-align: center;

            .image {
                text-align: center;

                img {
                    height: 12rem;
                }
            }

            .info {
                margin-top: 1rem;
                color: rgb(80, 79, 79);
                width: 100%;

                p {
                    margin: 1rem 0;
                }
            }
        }
    }
    .rows::-webkit-scrollbar {
        display: none;
    }
}
@media (max-width: 767px) {
    .Commitee {
        min-width: 80vw;
        grid-template-columns: 1fr;
        justify-items: center;

        .title {
            margin-top: 1rem;
            width: 20rem;
            border: none;
            border-bottom: 0.3rem solid #acacac;

            span {
                width: 100%;
            }
        }

        .rows {
            height: fit-content;
            grid-template-columns: repeat(2, 1fr);
            overflow: visible;
            padding: 0 3rem;
        }

        .row:hover {
            transform: none;
            box-shadow: none;
        }
    }
}
