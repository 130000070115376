:root {
    font-family: Jost;
}

.faculties {
    display: grid;
    grid-template-columns: 0fr 1fr;
    justify-content: center;
    align-items: center;

    .title {
        display: grid;
        justify-content: right;
        align-items: center;
        width: 8.5rem;
        border-right: 0.3rem solid #acacac;
        margin-right: 1rem;

        span {
            padding-right: 2rem;
            width: 3rem;
            font-size: 3.7rem;
            line-height: 4rem;
            overflow-wrap: break-word;
        }
    }
    .rows {
        display: grid;
        padding: 1rem;
        grid-template-columns: repeat(5, 1fr);
        gap: 2.5rem;
        margin: 1rem 1.5rem;
        overflow-y: scroll;
        height: 41rem;

        .container-y,
        .container-b {
            box-sizing: border-box;
            padding: 3rem 2rem 4rem 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 0.3rem solid #ffbf23;
            background: linear-gradient(to top, #d9d9d9a6 70%, transparent 70%);
            border-top-left-radius: 1.5rem;

            .mem-img {
                width: 6rem;
                height: 6rem;
                border: 0.14rem solid #7f808094;
                border-radius: 50%;
                background-color: white;
                overflow: hidden;
            }

            .mem-img img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }

            .mem-name {
                color: black;
                font-size: 1rem;
                margin-top: 1rem;
                overflow-x: unset;
            }

            .mem-position {
                font-size: 0.7rem;
                color: #000000;
                overflow-x: unset;
            }
        }

        .container-b {
            border: 0.3rem solid black;
        }
    }

    .rows::-webkit-scrollbar {
        display: none;
    }
}
/* 
.bottom-bar {
    position: relative;
    width: 100%;
    height: 100%;
}

.bottom-bar img {
    position: absolute;
    min-width: 5rem;
    right: 0;
    top: 6rem;
}

.bottom-bar-bg {
    position: absolute;
    width: 100%;
} */

@media (max-width: 767px) {
    .faculties {
        min-width: 80vw;
        grid-template-columns: 1fr;
        justify-items: center;

        .title {
            margin-top: 1rem;
            width: 20rem;
            border: none;
            border-bottom: 0.3rem solid #acacac;

            span {
                width: 100%;
            }
        }

        .rows {
            height: fit-content;
            overflow: visible;
            padding: 0 3rem;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
