.footer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-color: #ffbf23;
    position: relative;
    margin-top: 1rem;
    width: 100%;
    height: 18rem;
    overflow: hidden;
    z-index: -1;
    margin-top: 40px;

    > img {
        position: absolute;
        background-color: #ffbf23;
        right: 0;
        bottom: 0.5rem;
        height: 40px;
        aspect-ratio: 1/1;
        margin: 10px;
        object-fit: contain;
    }

    .contact {
        display: flex;
        justify-content: space-between;
        width: 87%;

        > :nth-child(2) {
            border-left: 0.13rem solid black;
            padding: 0.75rem;
            height: 10rem;
            font-size: 1.1rem;
        }
    }

    .images {
        padding-right: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        img {
            width: 9rem;
        }

        .links {
            img {
                width: 2rem;
                margin: 0.1rem 0.3rem;
            }
        }
    }

    > :last-child {
        width: 75%;
        border-top: 0.13rem solid black;
        text-align: center;
        margin-top: 1rem;
        padding: 0.5rem;
        font-size: medium;
        font-weight: bolder;
    }
}
