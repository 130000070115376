.Awards {
    display: grid;
    grid-template-columns: 0fr 1fr;
    justify-content: center;
    align-items: center;

    .title {
        display: grid;
        justify-content: right;
        align-items: center;
        width: 8.5rem;
        border-right: 0.3rem solid #acacac;
        margin-right: 1rem;

        span {
            padding-right: 2rem;
            width: 4rem;
            font-size: 3.7rem;
            line-height: 4rem;
            overflow-wrap: break-word;
        }
    }

    .rows {
        display: grid;
        align-items: center;
        padding: 1rem;
        grid-template-columns: repeat(4, 1fr);
        gap: 2.5rem;
        margin: 1rem 1.5rem;
        overflow-y: scroll;
        height: 41rem;

        .row2 {
            width: 100%;
            padding: 0 0.2rem;
            cursor: pointer;
            border-radius: 1rem;
            border: solid 0.1rem #acacac;
            border-bottom: solid 0.4rem orange;
            height: 24rem;

            .award-link {
                display: grid;
                grid-template-rows: 16rem 1fr;
                justify-items: center;
                align-items: center;
                width: 100%;
                text-decoration: none;

                .award-image {
                    margin-top: 3rem;
                    width: 60%;
                    display: block;
                    overflow: none;
                }

                .content {
                    color: rgb(80, 79, 79);
                    align-self: flex-start;
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: bolder;
                }
            }
        }

        .row2:hover {
            transform: scale(1.01);
            box-shadow: 0 0 1rem rgb(88, 90, 90);
        }
    }

    .rows::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .Awards {
        min-width: 80vw;
        grid-template-columns: 1fr;
        justify-items: center;

        .title {
            margin-top: 1rem;
            width: 17.5rem;
            border: none;
            border-bottom: 0.3rem solid #acacac;

            span {
                width: 100%;
            }
        }

        .rows {
            grid-template-columns: repeat(2, 1fr);
            height: fit-content;
            overflow: visible;
            padding: 0 3rem;
        }

        .row:hover {
            transform: none;
            box-shadow: none;
        }
    }
}
