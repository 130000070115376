::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #ffc1239c;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #ffb702;
    border-radius: 10px;
}

.RouteContainer {
    min-height: 80vh;
}

.title {
    text-align: center;
    font: inter 60px;
    font-size: 32px;
    font-weight: 600;
}

.newsletter-container {
    padding: 10px 10px 10px 10px;
}
