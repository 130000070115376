nav {
    .linksholder {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        /* Safari/Chrome, other WebKit */
        -webkit-box-sizing: border-box;
        /* Firefox, other Gecko */
        -moz-box-sizing: border-box;
        /* Opera/IE 8+ */
        box-sizing: border-box;
        border-bottom: 2px solid #ffbf23;
        padding: 0.75rem 3rem;

        .homeLink img {
            width: 13.5rem;
        }

        .nav-links {
            width: 80%;
            display: flex;
            margin-right: 3rem;
            gap: 2rem;
            justify-content: right;
            font-size: x-large;

            a {
                overflow-x: visible;
                font-size: 1.1rem;
                text-decoration: none;
                color: black;
                font-family: "inter";
                position: relative;
            }
            a:hover {
                color: black;
            }
            a::after {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                bottom: 0;
                left: 0;
                background: #ffbf23;
                transition: width 0.3s;
            }
            a:hover::after {
                width: 100%;
                transition: width 0.3s;
            }
        }
    }

    .active {
        font-weight: 600;
    }

    .Hamburg {
        display: none;
    }

    .m-nav-links {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    nav {
        .linksholder {
            padding: 2% 0 2% 1vw;
            flex-direction: row;

            .homeLink img {
                width: 13rem;
                margin-left: 1rem;
            }

            .nav-links {
                display: none;
            }

            .Hamburg {
                display: block;
                margin-right: 1.5rem;

                img {
                    height: 3rem;
                }
            }
        }

        .m-nav-links {
            display: grid;
            justify-items: center;
            padding: 0;
            position: absolute;
            background-color: #ffffffee;
            width: 100%;
            border-bottom: 2px solid #ffbf23;

            a {
                text-decoration: none;
                color: black;
                font-family: "inter";
                font-size: small;
                padding: 0.5rem;
            }
        }
    }
}
