.Gallery {
    display: grid;
    grid-template-columns: 0fr 1fr;
    justify-content: center;
    align-items: center;

    .title {
        display: grid;
        justify-content: right;
        align-items: center;
        width: 8.5rem;
        border-right: 0.3rem solid #acacac;
        margin-right: 1rem;

        span {
            padding-right: 2rem;
            width: 3rem;
            font-size: 3.7rem;
            line-height: 4rem;
            overflow-wrap: break-word;
        }
    }

    .rows {
        display: grid;
        padding: 1rem;
        grid-template-columns: 1fr;
        gap: 2.5rem;
        margin: 1rem 1.5rem;
        overflow-y: scroll;
        height: 41rem;

        .event {
            .event-title {
                font-size: 2rem;
                margin: 1rem 0;
                color: rgb(80, 79, 79);
                text-align: center;
                border-bottom: 0.1rem solid;
            }

            .images {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 0.2rem;

                .img {
                    object-fit: contain;
                    width: 21rem;
                    height: fit-content;
                    transition: 1s;
                }

                /* .img:hover {
                    transform: scale(1.05);
                } */
            }
        }
    }
    .rows::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .Gallery {
        min-width: 80vw;
        grid-template-columns: 1fr;
        justify-items: center;

        .title {
            margin-top: 1rem;
            width: 17.5rem;
            border: none;
            border-bottom: 0.3rem solid #acacac;

            span {
                width: 100%;
            }
        }

        .rows {
            height: fit-content;
            overflow: visible;
            padding: 0 3rem;

            .event {
                .images {
                    .img {
                        object-fit: contain;
                        height: fit-content;
                        width: 18rem;
                        transition: 1s;
                    }
                }
            }
        }

        .row:hover {
            transform: none;
            box-shadow: none;
        }
    }
}
