:root {
    font-family: Jost;
}

.newsletter {
    display: grid;
    grid-template-columns: 0fr 1fr;
    padding-top: 1.5rem;
    text-align: center;

    .title {
        display: grid;
        justify-content: right;
        align-items: center;
        width: 8.5rem;
        border-right: 0.3rem solid #acacac;
        margin-right: 1rem;

        span {
            padding-right: 2rem;
            width: 4rem;
            font-size: 3.7rem;
            line-height: 4rem;
            overflow-wrap: break-word;
        }
    }

    .content {
        border-color: #00000099;
        border-radius: 2rem;
        border-style: solid;
        margin: 0 5rem;
        border-width: 0.75rem 1rem 0.75rem 0.25rem;
        display: grid;
        gap: 3rem;
        grid-template-columns: repeat(3, 1fr);
        justify-items: center;
        padding: 2rem;
        height: 38rem;
        overflow-y: scroll;

        a:nth-child(even) {
            width: 15.57rem;
            height: 22rem;
            overflow: hidden;
            margin: 3rem 1.5rem 1rem;
            background-position: center;
        }

        a:nth-child(odd) {
            width: 15.57rem;
            height: 22rem;
            overflow: hidden;
            aspect-ratio: 1;
            margin: 1rem 1.5rem 3rem;
            background-position: center;
        }
    }

    .content::-webkit-scrollbar {
        display: none;
    }

    a:nth-last-child(1) {
        background-image: url("./Newsletters/ComingSoon.png");
        background-size: cover;
    }
    a:nth-child(1) {
        background-image: url("./Newsletters/202324.jpg");
        background-size: cover;
    }
    a:nth-child(2) {
        background-image: url("./Newsletters/202223.jpg");
        background-size: contain;
    }
    a:nth-child(3) {
        background-image: url("./Newsletters/202122.png");
        background-size: cover;
    }
    a:nth-child(4) {
        background-image: url("./Newsletters/202021.png");
        background-size: cover;
    }
    a:nth-child(5) {
        background-image: url("./Newsletters/201920.png");
        background-size: cover;
    }
    a:nth-child(6) {
        background-image: url("./Newsletters/201819.png");
        background-size: cover;
    }
    a:nth-child(7) {
        background-image: url("./Newsletters/201718.png");
        background-size: cover;
    }
}

@media (max-width: 767px) {
    .newsletter {
        grid-template-columns: 1fr;
        justify-items: center;

        .title {
            width: 26rem;
            display: flex;
            border: none;
            border-bottom: 0.3rem solid #acacac;

            span {
                width: 100%;
                padding: 0;
            }
        }

        .content {
            grid-template-columns: 1fr 1fr;
            padding: 0.5rem;
            border-width: 0.75rem 0.25rem 0.75rem 0.25rem;
            margin-top: 1.5rem;
            height: fit-content;
        }
    }
}
