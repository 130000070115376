.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
}

.loader {
    width: 48px;
    height: 48px;
    position: relative;
}

.loader:before {
    content: "";
    width: 48px;
    height: 5px;
    background: #ffbf23;
    position: absolute;
    top: 60px;
    left: 0;
    border-radius: 50%;
    animation: shadow324 0.5s linear infinite;
    transform-origin: center;
}

.loader:after {
    content: "";
    width: 100%;
    height: 100%;
    background: #ff8c00;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    animation: jump7456 0.5s linear infinite;
    transform-origin: center;
}

@keyframes jump7456 {
    15% {
        border-bottom-right-radius: 3px;
    }
    25% {
        transform: translateY(9px) rotate(22.5deg);
    }
    50% {
        transform: translateY(18px) scale(1, 0.9) rotate(45deg);
        border-bottom-right-radius: 40px;
    }
    75% {
        transform: translateY(9px) rotate(67.5deg);
    }
    100% {
        transform: translateY(0) rotate(90deg);
    }
}

@keyframes shadow324 {
    0%,
    100% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1);
    }
}
